import { track, useTracking, formSubmit } from '/machinery/tracking'
import { PageLayout } from '/components/pageOnly/PageLayout'
import { ContainerMd, ContainerSm } from '/components/Container'
import { HeadingXl, HeadingLg, HeadingMd } from '/components/typgraphy/Heading'
import { IntroTextMd } from '/components/typgraphy/IntroText'
import { Paragraph } from '/components/typgraphy/Paragraph'
import { Image } from '/components/content/Image'
import { Quote } from '/components/content/Quote'
import { Video } from '/components/content/Video'
import { List } from '/components/content/List'
import { ButtonWithArrow } from '/components/Button'
import { CookieOptions } from '/components/cookies/CookieOptions'
import { CookieOverview } from '/components/cookies/CookieOverview'
import styles from './Single.css'

export function Single({ content, hideCookieBar = undefined }) {
  return (
    <PageLayout {...{ hideCookieBar }}>
      <div className={styles.page}>
        <header className={styles.header}>
          <ContainerSm>
            <HeadingXl h={1}>{content.title}</HeadingXl>
          </ContainerSm>
        </header>

        <div className={styles.body}>
          {content.content.map((x, i) => (
            <Content key={i} type={x.type} content={x.content} />
          ))}
        </div>

        {content.cta && (
          <footer className={styles.footer}>
            <ContainerSm>
              <div className={styles.cta}>
                <HeadingLg h={2}>{content.cta.title}</HeadingLg>
                <ButtonWithArrow url={content.cta.link.url}>{content.cta.link.title}</ButtonWithArrow>
              </div>
            </ContainerSm>
          </footer>
        )}
      </div>
    </PageLayout>
  )
}

function Content({ type, content }) {
  switch (type) {
    case 'heading':
      return (
        <ContainerSm layoutClassName={styles.heading}>
          <HeadingMd h={2}>{content}</HeadingMd>
        </ContainerSm>
      )
    case 'video':
      return (
        <ContainerMd layoutClassName={styles.video}>
          <Video src={content.src} />
        </ContainerMd>
      )
    case 'image': {
      const Container = content.large ? ContainerMd : ContainerSm
      return (
        <Container layoutClassName={styles.image}>
          <Image src={content.src} alt={content.alt} />
        </Container>
      )
    }
    case 'intro':
      return (
        <ContainerSm layoutClassName={styles.intro}>
          <IntroTextMd html={content} />
        </ContainerSm>
      )
    case 'paragraph':
      return (
        <ContainerSm layoutClassName={styles.paragraph}>
          <Paragraph>{content}</Paragraph>
        </ContainerSm>
      )
    case 'quote':
      return (
        <ContainerSm layoutClassName={styles.quote}>
          <Quote author={content.author}>{content.text}</Quote>
        </ContainerSm>
      )

    case 'list':
      return (
        <ContainerSm layoutClassName={styles.list}>
          <List items={content} />
        </ContainerSm>
      )

    case 'cookies':
      return (
        <div className={styles.cookies}>
          <CookieOverview />
        </div>
      )

    case 'cookieOptions':
      return (
        <ContainerSm layoutClassName={styles.cookieOptions}>
          <SingleCookieOptions />
        </ContainerSm>
      )

    default:
      return <ContentError {...{ type, content }} />
  }
}

function ContentError({ type, content }) {
  React.useEffect(
    () => {
      reportError(new Error(`In ContentWithStickySubject: unknown content type '${type}'`))
    },
    [type]
  )

  if (process.env.NODE_ENV === 'production') return null

  return (
    <div>
      <strong>Unknown content type {type}</strong>
      {JSON.stringify(content, null, 2)}
    </div>
  )
}

function SingleCookieOptions() {
  const [tracking, setTracking] = useTracking()

  return (
    <CookieOptions value={tracking} onAcceptCookies={handleAcceptCookies} onRefuseCookies={handleRefuseCookies} />
  )

  function handleRefuseCookies(e) {
    e.preventDefault()
    track(formSubmit({ name: 'cookieOptionsForm' }))
    setTracking('rejected')
  }

  function handleAcceptCookies(e) {
    e.preventDefault()
    track(formSubmit({ name: 'cookieOptionsForm' }))
    setTracking('accepted')
  }
}

