import { useTheme } from '/machinery/Theme'
import { reportError } from '/machinery/reportError'
import { HeadingLg, HeadingMd } from '/components/typgraphy/Heading'
import { IntroTextMd } from '/components/typgraphy/IntroText'
import { Paragraph } from '/components/typgraphy/Paragraph'
import { Image } from '/components/content/Image'
import { Figure } from '/components/content/Figure'
import { ButtonWithArrow } from '/components/Button'
import styles from './ContentWithStickySubject.css'

export function ContentWithStickySubject({ id, subject, content: rawContent }) {
  const firstItemIsImage = rawContent[0]?.type === 'image'
  const cover = firstItemIsImage ? rawContent[0].content : null
  const content = React.useMemo(
    () => firstItemIsImage ? rawContent.slice(1) : rawContent,
    [rawContent, firstItemIsImage]
  )

  return (
    <section className={styles.component} {...{ id }}>
      <div className={styles.subject}>
        <HeadingLg h={2}>
          {subject}
        </HeadingLg>
      </div>

      {cover && (
        <div className={styles.cover}>
          <Image src={cover.src} alt={cover.alt} cover />
        </div>
      )}

      <div className={styles.content}>
        {content.map((x, i) => (
          <Content key={i} type={x.type} content={x.content} />
        ))}
      </div>
    </section>
  )
}

function Content({ type, content, h }) {
  switch (type) {
    case 'heading':
      return <HeadingMd layoutClassName={styles.heading} {...{ h }}>{content}</HeadingMd>
    case 'image':
      return <Image src={content.src} alt={content.alt}  layoutClassName={styles.image} />
    case 'figure':
      return <Figure image={content.image} caption={content.caption} layoutClassName={styles.figure} />
    case 'intro':
      return <IntroTextMd layoutClassName={styles.intro} html={content} />
    case 'paragraph':
      return <Paragraph layoutClassName={styles.paragraph}>{content}</Paragraph>
    case 'box':
      return <ContentBox h={h + 1} layoutClassName={styles.box} {...{ content }} />
    case 'download':
      return <ButtonWithArrow layoutClassName={styles.download} download url={content.url}>{content.title}</ButtonWithArrow>
    default:
      return <ContentError {...{ type, content }} />
  }
}

function ContentBox({ content, h, layoutClassName = undefined }) {
  const theme = useTheme()
  return (
    <div className={cx(styles.componentContentBox, theme.tertiary, theme.onTertiary, layoutClassName)}>
      {content.map((x, i) => (
        <Content key={i} type={x.type} content={x.content} {...{ h }} />
      ))}
    </div>
  )
}

function ContentError({ type, content }) {
  React.useEffect(
    () => {
      reportError(new Error(`In ContentWithStickySubject: unknown content type '${type}'`))
    },
    [type]
  )

  if (process.env.NODE_ENV === 'production') return null

  return (
    <div>
      <strong>Unknown content type {type}</strong>
      {JSON.stringify(content, null, 2)}
    </div>
  )
}
