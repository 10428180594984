const focusableElementsSelector = `
  a[href],
  area[href],
  button:not([disabled]),
  input:not([disabled]),
  textarea:not([disabled]),
  select:not([disabled]),
  [tabindex]:not([disabled])
`

export function useFocusTrap({ enabled }) {
  const [element, setElement] = React.useState(null)
  const focusedBeforeTrapRef = React.useRef(null)

  React.useEffect(
    () => {
      if (!enabled || !element) return

      focusedBeforeTrapRef.current = document.activeElement

      const { all, last, first } = getFocusableElements(element)
      const focused = first ?? element
      focused.focus()

      document.addEventListener('keydown', handleKeyDown)

      return () => {
        focusedBeforeTrapRef.current.focus()
        element.removeAttribute('tabindex')
        document.removeEventListener('keydown', handleKeyDown)
      }

      function handleKeyDown(e) {
        if (e.key === 'Tab') handleTab(e)
      }

      function handleTab(e) {
        if (!all.length) {
          e.preventDefault()
          return
        }

        if (e.shiftKey && document.activeElement === first) {
          e.preventDefault()
          last.focus()
        } else if (!e.shiftKey && document.activeElement === last) {
          e.preventDefault()
          first.focus()
        }
      }
    },
    [enabled, element]
  )

  return { ref: setElement }
}

function getFocusableElements(parent) {
  const elements = Array.from(parent.querySelectorAll(focusableElementsSelector))
  const [first] = elements ?? null
  const [last] = elements.slice(-1) ?? null
  return { all: elements, length: elements.length, first, last }
}
