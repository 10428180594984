import { useElementSize } from '@kaliber/use-element-size'
import { ThemeProvider } from '/machinery/Theme'
import { PageLayout } from '/components/pageOnly/PageLayout'
import { ContainerLg, ContainerSm } from '/components/Container'
import { Hero } from '/components/convenant/Hero'
import { ScrollNav } from '/components/convenant/ScrollNav'
import { themes } from '/themes'
import { ContentWithStickySubject } from '/components/ContentWithStickySubject'
import { ButtonWithArrow } from '/components/Button'
import styles from './Convenant.css'
import content from '/pages/Convenant.json'

const scrollNavItems = content.sections.map(x => ({
  anchor: x.id,
  title: x.subject
}))

export function Convenant() {
  const { ref: stickyRef, size: { height: stickyHeight } } = useElementSize()

  return (
    <ThemeProvider theme={themes.attention}>
      <PageLayout>
        <div className={styles.page}>
          <Hero
            layoutClassName={styles.hero}
            title={content.title}
            subtitle={content.subtitle}
          />

          <div ref={stickyRef} className={styles.sticky} style={{ marginTop: `-${stickyHeight}px` }}>
            <ScrollNav items={scrollNavItems} />
          </div>

          <ThemeProvider theme={themes.default}>
            <div className={styles.body}>
              {content.sections.map(section => (
                <ContainerLg key={section.id} layoutClassName={styles.section}>
                  <ContentWithStickySubject
                    id={section.id}
                    subject={section.title}
                    content={section.content}
                  />
                </ContainerLg>
              ))}
            </div>
          </ThemeProvider>
        </div>
      </PageLayout>
    </ThemeProvider>
  )
}
