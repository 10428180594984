import { animated, useSpring } from 'react-spring'
import { unlerp, clamp } from '@kaliber/math'
import { useElementSize } from '@kaliber/use-element-size'
import { ContainerLg } from '/components/Container'
import { useTheme } from '/machinery/Theme'
import styles from './ScrollNav.css'

/**
 * @param {Object} props - The scroll anchor data
 * @param {Object[]} props.items - The scroll anchor data
 * @param {string} props.items[].anchor - The id to scroll to (without #)
 * @param {string} props.items[].title - The link label to display
 */
export function ScrollNav({ items }) {
  const theme = useTheme()
  const elementRef = React.useRef(null)
  const anchors = items.map(x => x.anchor)
  const activeAnchor = useScrollSpy({ offset: -240, anchors })
  const [{ opacity }, setSpring] = useSpring(() => ({ opacity: 0 }))

  useScrollCallback(() => {
    const top = elementRef.current.getBoundingClientRect().top
    const opacity = unlerp({ start: 200, end: 0, input: top })
    setSpring.start({ opacity: clamp({ min: 0, max: 1, input: opacity }), immediate: true })
  })

  return (
    <nav ref={elementRef} className={styles.component}>
      <ContainerLg>
        <Anchors anchors={items} {...{ activeAnchor }} />
      </ContainerLg>

      <animated.div
        className={cx(styles.backdrop, theme.backgroundColor)}
        role='presentation'
        style={{ opacity }}
      />
    </nav>
  )
}

function Anchors({ anchors, activeAnchor }) {
  const theme = useTheme()
  const { ref: elementRef, size: { width: elementWidth } } = useElementSize()
  const { ref: listRef, size: { width: linksWidth } } = useElementSize()

  const hideLinks = linksWidth > elementWidth

  return (
    <div className={styles.componentAnchors} ref={elementRef}>
      <div className={styles.links}>
        <ul
          className={cx(styles.list, theme.color)}
          ref={listRef}
          aria-hidden={hideLinks ? 'true' : 'false'}
        >
          {anchors.map(({ anchor, title }, i) => (
            <li key={[i, anchor].join('__')}>
              <Anchor {...{ anchor }} active={anchor === activeAnchor}>{title}</Anchor>
            </li>
          ))}
        </ul>
      </div>

      {hideLinks && (
        <select
          className={cx(styles.select, theme.color, !activeAnchor && styles.isPlaceholder)}
          value={activeAnchor ?? ''}
          onChange={handleAnchorSelect}
        >
          <option value=''>Snel navigeren</option>

          {anchors.map(({ anchor, title }, i) => (
            <option key={[i, anchor].join('__')} value={anchor}>{title}</option>
          ))}
        </select>
      )}
    </div>
  )

  function handleAnchorSelect(e) {
    window.location.hash = e.currentTarget.value
  }
}

function Anchor({ anchor, children, active }) {
  return (
    <a
      href={`#${anchor}`}
      className={cx(styles.componentAnchor, active && styles.isActive)}
    >
      {children}
    </a>
  )
}

function useScrollCallback(callback) {
  const callbackRef = React.useRef(callback)
  callbackRef.current = callback

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }

      function handleScroll() {
        callbackRef.current(window.pageYOffset)
      }
    },
    []
  )
}

function useScrollSpy({ anchors, offset }) {
  const [anchor, setAnchor] = React.useState(null)

  React.useEffect(
    () => {
      setAnchor(window.location.href.split('#')[0] || null)
    },
    []
  )

  useScrollCallback(() => {
    const closestAnchor = anchors
      .map(id => ({
        top: document.getElementById(id).getBoundingClientRect().top + offset,
        anchor: id
      }))
      .sort((a, b) => a.top - b.top)
      .reduce(
        (result, { top, anchor }) => {
          if (top > 0 || Math.abs(top) >= result.distance) return result
          return { distance: Math.abs(top), anchor }
        },
        { distance: Infinity, anchor: null }
      )
      .anchor

    if (closestAnchor !== anchor) {
      setAnchor(closestAnchor)
      replaceHash(closestAnchor)
    }
  })

  return anchor
}

// Updates hash withouth triggering scroll
function replaceHash(hash) {
  const currentHash = window.location.hash.slice(1)
  const baseUrl = window.location.href.split('#')[0]
  if (currentHash === hash) return

  window.history.replaceState(
    null,
    document.title,
    baseUrl + (hash ? ('#' + hash) : '')
  )
}
