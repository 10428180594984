import { ContainerLg } from '/components/Container'
import styles from './CookieOverview.css'

export function CookieOverview() {
  return (
    <div className={styles.component}>
      <ContainerLg>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.cell}>Naam</th>
              <th className={styles.cell}>Doel</th>
              <th className={styles.cell}>Cookiekeuze</th>
            </tr>
          </thead>

          <tbody>
            <tr className={styles.row}>
              <td className={styles.cell}>_ga, _gat_UA-xxxxxxxxx-x, _ga_&lt;container-id&gt;, _gid</td>
              <td className={styles.cell}>Deze cookies zijn van Google en worden gebruikt voor het bijhouden van bezoekersstatistieken en om
                internetgedrag van verschillende apparaten te koppelen. Ook wordt het _ga cookie gebruikt om analyses te doen.
                Hiermee verbeteren we onze websites. We bewaren dit cookie twee jaar.<br />
                Het _gat cookie bewaren we 1 minuut</td>
              <td className={styles.cell}>*</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.cell}>rabobank-cooperatief-convenant-accept-tracking-cookies</td>
              <td className={styles.cell}>Dit cookie gebruiken we om je cookiekeuze op te slaan</td>
              <td className={styles.cell}>*</td>
            </tr>
          </tbody>
        </table>
      </ContainerLg>
    </div>
  )
}
