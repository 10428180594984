import { useTheme } from '/machinery/Theme'
import { Paragraph } from '/components/typgraphy/Paragraph'
import styles from './List.css'

export function List({ items }) {
  const theme = useTheme()
  return (
    <ul className={styles.component}>
      {items.map((item, i) => (
        <li key={i} className={cx(styles.item, theme.accent)}>
          <div className={theme.color}><Paragraph>{item}</Paragraph></div>
        </li>
      ))}
    </ul>
  )
}
