import { Link } from '/components/Link'
import imageLogo from '/images/logo.svg'
import styles from './Footer.css'

export function Footer() {
  return (
    <div className={styles.component}>
      <div className={styles.logo}>
        <img alt="De coöperatieve Rabobank" src={imageLogo} />
      </div>

      <ul className={styles.links}>
        <li>
          <Link url='/colofon'>Colofon</Link>
        </li>

        <li>
          <Link url='/cookies'>Cookies</Link>
        </li>
      </ul>
    </div>
  )
}
