import styles from './IntroText.css'

export function IntroTextMd({ layoutClassName = undefined, html }) {
  return <IntroTextBase className={styles.componentMd} {...{ html, layoutClassName }} />
}

export function IntroTextLg({ layoutClassName = undefined, html }) {
  return <IntroTextBase className={styles.componentLg} {...{ html, layoutClassName }} />
}

function IntroTextBase({ className, layoutClassName, html }) {
  return <p className={cx(styles.componentBase, className, layoutClassName)} dangerouslySetInnerHTML={{ __html: html }} />
}
