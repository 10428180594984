import { Image } from '/components/content/Image'
import styles from './Figure.css'

export function Figure({ image, caption, layoutClassName = undefined }) {
  return (
    <figure className={cx(styles.component, layoutClassName)}>
      <Image
        src={image.src}
        alt={image.alt}
        cover={image.cover}
      />

      <figcaption className={styles.caption}>
        {caption}
      </figcaption>
    </figure>
  )
}
