import { ThemeProvider } from '/machinery/Theme'
import { PageLayout } from '/components/pageOnly/PageLayout'
import { ContainerLg } from '/components/Container'
import { Hero } from '/components/landing/Hero'
import { Teaser } from '/components/landing/Teaser'
import { themes } from '/themes'
import styles from './Landing.css'
import content from './Landing.json'

export function Landing() {
  return (
    <ThemeProvider theme={themes.dark}>
      <PageLayout>
        <div className={styles.page}>
          <Hero layoutClassName={styles.hero} {...{ content }} />

          <ContainerLg layoutClassName={styles.interviewTeaser}>
            <ThemeProvider theme={themes.default}>
              <Teaser
                title={content.teaser.title}
                level={2}
                description={content.teaser.description}
                image={content.teaser.image}
                link={content.teaser.link}
              />
            </ThemeProvider>
          </ContainerLg>
        </div>
      </PageLayout>
    </ThemeProvider>
  )
}
