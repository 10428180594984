import Cookies from 'js-cookie'

const ACCEPT_TRACKING_COOKIE_NAME = 'rabobank-cooperatief-convenant-accept-tracking-cookies'
const COOKIE_DURATION = 365 /* 1 year */

const listeners = new Set()

export function useTracking() {
  const [tracking, setTracking] = React.useState(null)

  React.useEffect(
    () => {
      listeners.add(setTracking)
      return () => {
        listeners.delete(setTracking)
      }
    },
    []
  )

  const set = React.useCallback(
    value => {
      Array.from(listeners).forEach(fn => {
        fn(['accepted', 'rejected', 'unanswered'].includes(value) ? value : 'unanswered')
      })

      Cookies.set(
        ACCEPT_TRACKING_COOKIE_NAME,
        value,
        { expires: COOKIE_DURATION }
      )
    },
    []
  )

  React.useEffect(
    () => {
      set(Cookies.get(ACCEPT_TRACKING_COOKIE_NAME))
    },
    [set]
  )

  return [tracking, set]
}

export function cookieBarIsOpen() {
  return {
    event: 'cookie_bar_opened',
    cookieBarIsOpen: 'true'
  }
}

export function cookiesAccepted() {
  return {
    event: 'tracking_cookies_accepted',
    anonymizeIp: 'false',
    allowAdFeatures: 'true'
  }
}

export function cookiesRejected() {
  return {
    event: 'tracking_cookies_rejected',
    anonymizeIp: 'true',
    allowAdFeatures: 'false'
  }
}

export function formSubmit(name) {
  return { event: 'form.submit', name }
}

export function track(data) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export function getTracking() {
  return
}

export function setTracking(bool) {
  Cookies.set(ACCEPT_TRACKING_COOKIE_NAME, bool ? 'accepted' : 'rejected', { expires: COOKIE_DURATION })
}
