import { useSpring, animated, config } from 'react-spring'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useElementSize } from '@kaliber/use-element-size'
import { mergeRefs } from '/machinery/mergeRefs'
import styles from './BackgroundVideo.css'

export function BackgroundVideo({ layoutClassName, srcSet }) {
  const videoRef = React.useRef(null)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { ref: qualityRef, quality } = useVideoQualityLevel({ ratio: 16 / 9 })
  const src = srcSet[quality]

  const [{ opacity }, setSpring] = useSpring(() => ({ opacity: 0, config: config.molasses }))

  React.useEffect(
    () => { isInViewport ? videoRef.current.play() : videoRef.current.pause() },
    [isInViewport]
  )

  return (
    <animated.div className={cx(styles.component, layoutClassName)} style={{ opacity }}>
      <video
        className={styles.video}
        controlsList="nodownload"
        loop
        muted
        playsInline
        autoPlay
        preload="metadata"
        src={src ?? srcSet[0]}
        ref={mergeRefs(videoRef, qualityRef, visibilityRef)}
        onLoadedData={handleFirstFrameLoaded}
      />
    </animated.div>
  )

  function handleFirstFrameLoaded() {
    setSpring.start({ opacity: 1 })
  }
}

function useVideoQualityLevel({ ratio = 16 / 9 }) {
  const [quality, setQuality] = React.useState(-1)
  const { ref, size: { width, height } } = useElementSize()

  React.useEffect(
    () => {
      const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection

      if (connection?.saveData) return 0

      const connectionType = connection?.effectiveType ?? '4g'
      const maxLevel = {
        'slow-2g': 0,
        '2g': 0,
        '3g': 1,
        '4g': 3
      }[connectionType]

      const neededLevel = [
        [360 * ratio, 360],
        [540 * ratio, 540],
        [720 * ratio, 720],
        [Infinity, Infinity],
      ].findIndex(([w, h]) => (
        w >= width &&
        h >= height
      ))

      setQuality(Math.min(neededLevel, maxLevel))
    },
    [width, height, ratio]
  )


  return { ref, quality }
}
