import { ThemeProvider } from '/machinery/Theme'
import { useTracking, track, cookiesAccepted, cookiesRejected } from '/machinery/tracking'
import { Landing } from '/pages/Landing'
import { Convenant } from '/pages/Convenant'
import { Single } from '/pages/Single'
import { NotFound } from '/pages/NotFound'
import { themes } from '/themes'
import interview from '/pages/Interview.json'
import colofon from '/pages/Colofon.json'
import cookies from '/pages/Cookies.json'
import rapporten from '/pages/Rapporten.json'
import styles from './App.css'

export default function App({ config, location }) {
  const [tracking] = useTracking()

  React.useEffect(
    () => {
      if (tracking === 'accepted') track(cookiesAccepted())
      else if (tracking === 'rejected') track(cookiesRejected())
    },
    [tracking]
  )

  return (
    <ThemeProvider theme={themes.default}>
      <div className={styles.app}>
        {
          location.pathname === '/' ? <Landing /> :
          location.pathname === '/convenant' ? <Convenant /> :
          location.pathname === '/interview' ? <Single content={interview} /> :
          location.pathname === '/colofon' ? <Single content={colofon} /> :
          location.pathname === '/cookies' ? <Single content={cookies} hideCookieBar /> :
          location.pathname === '/rapporten' ? <Single content={rapporten} /> :
          <NotFound />
        }
      </div>
    </ThemeProvider>
  )
}
