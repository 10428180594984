import ReactPlayer from 'react-player/vimeo'
import { useSpring, animated, config } from 'react-spring'
import { useTheme } from '/machinery/Theme'
import { Icon } from '/components/Icon'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import iconPlay from '/images/icons/play.raw.svg'
import styles from './Video.css'

export function Video({ src }) {
  const theme = useTheme()
  const [ready, setReady] = React.useState(false)
  const [playing, setPlaying] = React.useState(false)
  const [hovering, setHovering] = React.useState(false)
  const isMounted = useRenderOnMount()

  const animatedReadyProps = useSpring({ opacity: ready ? 1 : 0 })
  const animatedButtonProps = useSpring({
    opacity: (!ready || playing) ? 0 : 1,
    transform: `scale(${(!ready || playing) ? 0.5 : hovering ? 1.2 : 1})`,
    config: config.stiff
  })

  return (
    <div className={styles.component}>
      <animated.div className={styles.player} style={animatedReadyProps}>
        {isMounted && (
          <ReactPlayer
            url={src}
            controls
            width='100%'
            height='100%'
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onReady={() => setReady(true)}
            {...{ playing }}
          />
        )}
      </animated.div>

      <button
        type='button' onClick={() => setPlaying(true)}
        className={styles.button}
        style={{ pointerEvents: playing ? 'none' : 'auto' }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <animated.div
          className={cx(styles.play, theme.secondary)}
          role="presentation"
          style={animatedButtonProps}
        >
          <div className={cx(styles.icon, theme.onSecondary)}>
            <Icon icon={iconPlay} />
          </div>
        </animated.div>

        <span className={styles.screenreader}>Afspelen</span>
      </button>
    </div>
  )
}
