import { useTheme } from '/machinery/Theme'
import styles from './CookieOptions.css'
import iconStar from '/images/icons/star.raw.svg'

export function CookieOptions({ layoutClassName = undefined, value, onAcceptCookies, onRefuseCookies }) {
  return (
    <form className={cx(styles.component, layoutClassName)}>
      <CookieOption
        label='Haal het meeste uit onze website én websites van derden, met advertenties die voor jou relevant zijn. Jouw internetgedrag wordt gedeeld met Rabobank en de derden die cookies plaatsen.'
        id="accept"
        value="accepted"
        checked={value === 'accepted'}
        stars={2}
        onChange={onAcceptCookies}
      />

      <CookieOption
        label='Voor een goedwerkende website worden alleen functionele en analytische cookies geplaatst.'
        id="decline"
        value="rejected"
        checked={value === 'rejected'}
        stars={1}
        onChange={onRefuseCookies}
      />
    </form>
  )
}

function CookieOption({ label, id, checked, value, stars, onChange }) {
  const theme = useTheme()
  return (
    <div className={cx(styles.componentCookieOption, checked ? theme.primary : theme.tertiary, checked ? theme.onPrimary : theme.onTertiary )}>
      <input className={styles.cookieOptionInput} type="radio" name="cookieRadio" {...{ id, value, checked, onChange }} />
      <label className={styles.cookieOptionLabel} htmlFor={id}>
        <div className={styles.cookieOptionIcons}>
          {[...Array(stars)].map((_, i) => <span key={i} className={styles.cookieOptionStar} dangerouslySetInnerHTML={{ __html: iconStar }} />)}
        </div>
        <div className={styles.cookieOptionText}>{label}</div>
      </label>
    </div>
  )
}
