import { ButtonWithArrow } from '/components/Button'
import { HeadingLinkLg } from '/components/typgraphy/Heading'
import styles from './Teaser.css'

export function Teaser({ title, level = undefined, description, image, link }) {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingLinkLg
          url={link.to}
          layoutClassName={cx(styles.title, styles.relativeToParent)}
          relativeToParent
          {...{ level }}
        >
          {title}
        </HeadingLinkLg>

        <p className={styles.description}>{description}</p>

        <div className={styles.callToAction} aria-hidden="true">
          <ButtonWithArrow url={link.to}>{link.title}</ButtonWithArrow>
        </div>
      </div>

      <div className={styles.cover}>
        <img className={styles.image} src={image.src} alt={image.alt} />
      </div>
    </div>
  )
}
