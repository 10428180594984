import { useTheme } from '/machinery/Theme'
import { track, useTracking, cookieBarIsOpen, formSubmit } from '/machinery/tracking'
import { HeadingMd } from '/components/typgraphy/Heading'
import { ContainerLg } from '/components/Container'
import { CookieOptions } from '/components/cookies/CookieOptions'
import { Button, ButtonWithArrow } from '/components/Button'
import { Link } from '/components/Link'

import styles from './CookieBar.css'

export function CookieBar({ cookieUrl }) {
  const theme = useTheme()
  const [tracking, setTracking] = useTracking()
  const [showCookieBar, setShowCookieBar] = React.useState(false)
  const [showCookieOptions, setShowCookieOptions] = React.useState(false)

  React.useEffect(
    () => {
      if (tracking === 'unanswered') {
        track(cookieBarIsOpen())
        setShowCookieBar(true)
      }
    },
    [tracking]
  )

  return showCookieBar && (
    <div className={cx(styles.component, theme.root)}>
      <ContainerLg>
        <div className={styles.layout}>
          <HeadingMd level={2} layoutClassName={styles.heading}>Ook wij gebruiken cookies</HeadingMd>

          <div className={styles.content}>
            <div className={cx(styles.text, showCookieOptions && styles.isHiddenOnSmallScreens)}>
              <p className={styles.description}>
                Door op ‘Akkoord’ te klikken, accepteer je alle cookies (en vergelijkbare technieken) op onze website. Hiermee kunnen wij en derden jouw internetgedrag op onze website volgen en verwerken daarbij jouw persoonsgegevens. Door ons en derden kan een profiel worden gemaakt waarmee gepersonaliseerde advertenties kunnen worden getoond. Door op ‘instellingen wijzigen’ te klikken, kun je een andere cookie voorkeur kiezen.
              </p>

              <Link url={cookieUrl}>Meer informatie over cookies</Link>
            </div>

            <ActionButtons layoutClassName={styles.buttons}>
              <ButtonWithArrow onClick={handleAcceptCookies}>Akkoord</ButtonWithArrow>
              <Button onClick={() => { setShowCookieOptions(x => !x) }}>Instellingen wijzigingen</Button>
            </ActionButtons>
          </div>

          {showCookieOptions && (
            <CookieOptions
              value={tracking}
              layoutClassName={styles.options}
              onAcceptCookies={handleAcceptCookies}
              onRefuseCookies={handleRefuseCookies}
            />
          )}

          {showCookieOptions && (
            <div className={cx(styles.mobileInfoLink, styles.isHiddenOnLargeScreens)}>
              <Link url={cookieUrl}>Meer informatie over cookies</Link>
            </div>
          )}
        </div>
      </ContainerLg>
    </div>
  )


  function handleRefuseCookies(e) {
    e.preventDefault()
    track(formSubmit({ name: 'cookieBarForm' }))
    setTracking('rejected')
    setShowCookieBar(false)
  }

  function handleAcceptCookies(e) {
    e.preventDefault()
    track(formSubmit({ name: 'cookieBarForm' }))
    setTracking('accepted')
    setShowCookieBar(false)
  }
}

function ActionButtons({ layoutClassName, children }) {
  return (
    <div className={cx(styles.componentActionButtons, layoutClassName)}>
      {children}
    </div>
  )
}


