import { animated, useSpring, useSprings, to } from 'react-spring'
import { lerp } from '@kaliber/math'
import { useTheme } from '/machinery/Theme'
import { HeadingXl } from '/components/typgraphy/Heading'
import styles from './MainMenu.css'

const links = [
  { to: '/convenant', title: 'Het convenant' },
  { to: '/interview', title: 'Interview' },
  { to: '/rapporten', title: 'Regiorapporten' },
  { to: '/colofon', title: 'Colofon' },
]

export function MainMenu({ title, expanded, id, layoutClassName }) {
  const { opacity } = useSpring({ opacity: expanded ? 1 : 0 })

  const cornerPoints = useSprings(4, [0, 1, 2, 3].map(i => ({
    from: { value: 0 },
    value: expanded ? 1 : 0,
    delay: i * 33,
    config: { velocity: 0.02 },
  })))

  const items = useSprings(
    links.length,
    links.map((_, i) => ({
      from: { opacity: 0 },
      opacity: expanded ? 1 : 0,
      transform: `translate3d(0, ${expanded ? 0 : 25}%, 0)`,
      delay: 200 + i * 50
    }))
  )

  return (
    <animated.nav
      className={cx(styles.component, layoutClassName)}
      aria-hidden={expanded ? 'false' : 'true'}
      style={{ opacity, clipPath: calculateClipPath(cornerPoints) }}
      {...{ id }}
    >
      <ul className={styles.links}>
        {links.map((link, i) => (
          <animated.li key={i} style={items[i]}>
            <Link url={link.to}>{link.title}</Link>
          </animated.li>
        ))}
      </ul>

      <div className={styles.title}>
        <a href='/'>{title}</a>
      </div>
    </animated.nav>
  )
}

function calculateClipPath(springs) {
  const near = x => lerp({ start: 5, end: 0, input: x })
  const far = x => lerp({ start: 95, end: 100, input: x })

  return to(
    springs.map(x => x.value),
    (a, b, c, d) => `polygon(
      ${near(a)}% ${near(a)}%,
      ${far(b)}% ${near(b)}%,
      ${far(c)}% ${far(c)}%,
      ${near(d)}% ${far(d)}%
    )`
  )
}

function Link({ url, children }) {
  const theme = useTheme()

  return (
    <a className={cx(styles.componentLink, theme.accent)} href={url}>
      <HeadingXl>{children}</HeadingXl>
    </a>
  )
}
