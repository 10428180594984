import { PageLayout } from '/components/pageOnly/PageLayout'
import { ContainerLg } from '/components/Container'
import { HeadingXl } from '/components/typgraphy/Heading'
import styles from './NotFound.css'

export function NotFound() {
  return (
    <PageLayout>
      <div className={styles.page}>
        <ContainerLg>
          <HeadingXl h={1}>404: Niet gevonden</HeadingXl>
        </ContainerLg>
      </div>
    </PageLayout>
  )
}
