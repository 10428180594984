import styles from './Heading.css'

const hRenderers = {
  1: ({ children, className }) => <h1 {...{ className }}>{children}</h1>,
  2: ({ children, className }) => <h2 {...{ className }}>{children}</h2>,
  3: ({ children, className }) => <h3 {...{ className }}>{children}</h3>,
  4: ({ children, className }) => <h4 {...{ className }}>{children}</h4>,
  default: ({ children, className }) => <strong {...{ className }}>{children}</strong>,
}

export function HeadingXl({ h = 1, layoutClassName = undefined, children }) {
  return (
    <HeadingBase className={styles.componentXl} {...{ h, children, layoutClassName }} />
  )
}

export function HeadingLg({ h = 2, layoutClassName = undefined, children }) {
  return (
    <HeadingBase className={styles.componentLg} {...{ h, children, layoutClassName }} />
  )
}

export function HeadingMd({ h = 3, layoutClassName = undefined, children }) {
  return (
    <HeadingBase className={styles.componentMd} {...{ h, children, layoutClassName }} />
  )
}

export function HeadingLinkLg({ h = 2, to, relativeToParent = undefined, layoutClassName = undefined, children }) {
  return (
    <HeadingBase
      className={cx(styles.componentLinkLg, relativeToParent && styles.relativeToParent)}
      {...{ h, to, children, layoutClassName }}
    />
  )
}

function HeadingBase({ h, to = undefined, className, layoutClassName, children }) {
  const HBase = hRenderers[h] ?? hRenderers.default
  return (
    <HBase className={cx(styles.componentBase, className, layoutClassName)}>
      {to ? <a href={to} className={cx(styles.link, styles.relativeToParent)}>{children}</a> : children}
    </HBase>
  )
}
