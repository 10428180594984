import { Icon } from '/components/Icon'
import iconHamburger from '/images/icons/hamburger.raw.svg'
import iconClose from '/images/icons/close.raw.svg'
import styles from './Hamburger.css'

export function Hamburger({ expanded, onClick, controls }) {
  return (
    <div className={styles.component}>
      <button
        type='button'
        className={styles.button}
        aria-expanded={expanded ? 'true' : 'false'}
        aria-controls={controls}
        {...{ onClick }}
      >
        <Icon icon={expanded ? iconClose : iconHamburger} />
        <span className={styles.screenreader}>{expanded ? 'Sluit menu' : 'Open menu'}</span>
      </button>
    </div>
  )
}
