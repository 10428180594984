import { ThemeProvider } from '/machinery/Theme'
import { Header } from '/components/pageOnly/Header'
import { Footer } from '/components/pageOnly/Footer'
import { CookieBar } from '/components/cookies/CookieBar'
import { themes } from '/themes'
import styles from './PageLayout.css'

export function PageLayout({ children, hideCookieBar = undefined }) {
  return (
    <div className={styles.component_root}>
      <header className={styles.header}>
        <Header title='Coöperatief convenant' />
      </header>

      <main className={styles.main}>{children}</main>

      <ThemeProvider theme={themes.default}>
        <footer className={styles.footer}>
          <Footer />
        </footer>
      </ThemeProvider>

      {!hideCookieBar && (
        <ThemeProvider theme={themes.dark}>
          <aside className={styles.cookieBar} data-x='cookie-bar'>
            <CookieBar cookieUrl='/cookies' />
          </aside>
        </ThemeProvider>
      )}
    </div>
  )
}
