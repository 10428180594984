import styles from './Quote.css'

export function Quote({ children, author }) {
  return (
    <figure className={styles.component}>
      <div className={styles.text}>{children}</div>
      <figcaption className={styles.author}>{author}</figcaption>
    </figure>
  )
}
