import { useTheme } from '/machinery/Theme'
import styles from './Link.css'

export function Link({ url, onClick = undefined, children }) {
  const Base = url ? LinkABase : LinkButtonBase
  const theme = useTheme()

  return (
    <Base className={cx(styles.component, theme.accent)} {...{ url, onClick, children }} />
  )
}

function LinkABase({ url, onClick, children, className }) {
  return (
    <a className={cx(styles.componentABase, className)} href={url} {...{ onClick }}>{children}</a>
  )
}

function LinkButtonBase({ onClick, children, className }) {
  return (
    <button className={cx(styles.componentButtonBase, className)} {...{ onClick, children }} />
  )
}
