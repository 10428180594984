import { ThemeProvider, useTheme } from '/machinery/Theme'
import { HeadingLg } from '/components/typgraphy/Heading'
import { IntroTextLg } from '/components/typgraphy/IntroText'
import { ButtonWithArrow } from '/components/Button'
import { BackgroundVideo } from '/components/BackgroundVideo'
import { Icon } from '/components/Icon'
import iconCaretDown from '/images/icons/caret-down.raw.svg'
import styles from './Hero.css'
import { themes } from '/themes'

export function Hero({ content, layoutClassName }) {
  const theme = useTheme()
  const elementRef = React.useRef(null)

  return (
    <div ref={elementRef} className={cx(styles.component, theme.root, layoutClassName)}>
      <div className={styles.container}>
        <ThemeProvider theme={themes.default}>
          <Content content={content.hero} layoutClassName={styles.content} />
        </ThemeProvider>
      </div>

      <BackgroundVideo layoutClassName={styles.video} srcSet={content.hero.video} />

      <button type='button' className={styles.scrollIndicator} onClick={scrollDown}>
        <span className={styles.scrollIndicatorIcon}><Icon icon={iconCaretDown} /></span>
      </button>
    </div>
  )

  function scrollDown() {
    const y = elementRef.current.getBoundingClientRect().bottom + window.pageYOffset
    window.scrollTo(0, y)
  }
}

function Content({ content, layoutClassName = undefined }) {
  const theme = useTheme()

  return (
    <div className={cx(styles.componentContent, theme.root, layoutClassName )}>
      <HeadingLg level={1}>{content.title}</HeadingLg>

      <p className={styles.intro}>{content.intro}</p>

      <footer className={styles.footer}>
        <ButtonWithArrow url={content.link.url}>{content.link.title}</ButtonWithArrow>
      </footer>
    </div>
  )
}
