import { ThemeProvider, useTheme } from '/machinery/Theme'
import { useFocusTrap } from '/machinery/useFocusTrap'
import { Hamburger } from '/components/Hamburger'
import { MainMenu } from '/components/pageOnly/MainMenu'
import { themes } from '/themes'
import styles from './Header.css'

export function Header({ title }) {
  const [navExpanded, setNavExpanded] = React.useState(false)
  const inheritedTheme = useTheme()
  const theme = navExpanded ? themes.default : inheritedTheme
  const { ref } = useFocusTrap({ enabled: navExpanded })

  useEscape(() => setNavExpanded(false))

  return (
    <div className={cx(styles.component, theme.color)}>
      <div className={styles.title}>
        <a href='/'>{title}</a>
      </div>

      <div className={styles.focusWrapper} {...{ ref }}>
        <ThemeProvider theme={themes.default}>
          <MainMenu
            id='navigation'
            expanded={navExpanded}
            layoutClassName={styles.mainMenu}
            {...{ title }}
          />
        </ThemeProvider>

        <div className={styles.hamburger}>
          <Hamburger
            expanded={navExpanded}
            controls='navigation'
            onClick={() => setNavExpanded(x => !x)}
          />
        </div>
      </div>
    </div>
  )
}

function useEscape(callback) {
  const callbackRef = React.useRef(callback)
  callbackRef.current = callback

  React.useEffect(
    () => {
      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
      function handleKeyDown(e) {
        if (['Esc', 'Escape'].includes(e.key)) {
          callbackRef.current && callbackRef.current()
        }
      }
    }
  )
}
