import styles from './Container.css'

export function ContainerLg({ children, layoutClassName = undefined }) {
  return (
    <ContainerBase className={styles.componentLg} {...{ children, layoutClassName }} />
  )
}

export function ContainerMd({ children, layoutClassName = undefined }) {
  return (
    <ContainerBase className={styles.componentMd} {...{ children, layoutClassName }} />
  )
}

export function ContainerSm({ children, layoutClassName = undefined }) {
  return (
    <ContainerBase className={styles.componentSm} {...{ children, layoutClassName }} />
  )
}

function ContainerBase({ children, className, layoutClassName }) {
  return (
    <div className={cx(styles.componentBase, className, layoutClassName)}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
