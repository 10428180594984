import { useTheme } from '/machinery/Theme'
import { HeadingXl } from '/components/typgraphy/Heading'
import { ContainerLg } from '/components/Container'
import { Icon } from '/components/Icon'
import iconCaretDown from '/images/icons/caret-down.raw.svg'
import styles from './Hero.css'

export function Hero({ title, subtitle, layoutClassName = undefined }) {
  const elementRef = React.useRef(null)
  const theme = useTheme()

  return (
    <div ref={elementRef} className={cx(styles.component, layoutClassName, theme.root)}>
      <ContainerLg layoutClassName={styles.titleContainer}>
        <h1 className={styles.title}>
          <div>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </h1>
      </ContainerLg>

      <button type='button' className={styles.scrollIndicator} onClick={scrollDown}>
        <span className={styles.scrollIndicatorIcon}><Icon icon={iconCaretDown} /></span>
      </button>
    </div>
  )

  function scrollDown() {
    const y = elementRef.current.getBoundingClientRect().bottom + window.pageYOffset
    window.scrollTo(0, y)
  }
}
