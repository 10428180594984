import { useTheme } from '/machinery/Theme'
import { Icon } from '/components/Icon'
import { themes } from '/themes'
import styles from './Button.css'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'

export function Button({ children, url = undefined, onClick = undefined, download = undefined, layoutClassName = undefined }) {
  return <ButtonBase {...{ url, onClick, download, layoutClassName, children }} />
}

export function ButtonWithArrow({ children, url = undefined, onClick = undefined, download = undefined, layoutClassName = undefined }) {
  return <ButtonBase icon={iconArrowRight} {...{ url, onClick, download, layoutClassName, children }} />
}

function ButtonBase({ url, onClick, icon = undefined, download, layoutClassName, children }) {
  const Base = url ? ButtonABase : ButtonButtonBase
  const theme = getCtaTheme(useTheme())

  return (
    <Base {...{ url, onClick, download, children, layoutClassName }}>
      <div className={cx(styles.componentBase, theme.root)}>
        <span className={styles.text}>{children}</span>
        {icon && <span className={styles.icon}><Icon icon={iconArrowRight} /></span>}
      </div>
    </Base>
  )
}

function ButtonABase({ url, onClick, children, download, layoutClassName }) {
  return (
    <a className={cx(styles.componentABase, layoutClassName)} href={url} {...{ onClick, download }}>{children}</a>
  )
}

function ButtonButtonBase({ onClick, children, layoutClassName }) {
  return (
    <button className={cx(styles.componentButtonBase, layoutClassName)} {...{ onClick, children }} />
  )
}

function getCtaTheme(theme) {
  switch (theme) {
    case themes.default:
      return themes.attention
    case themes.attention:
      return themes.default
    case themes.dark:
      return themes.default
    default:
      throw new Error('No cta theme set for theme')
  }
}
